import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SectionLight from "./sections/section-light"
import { renderLinks } from "../util/render-links"
import { StaticImage } from "gatsby-plugin-image"

function faqItem(item) {
  return (
    <li key={item.question}>
      <AnchorLink className="uk-accordion-title" to="">
        {item.question}
      </AnchorLink>
      <div className="uk-accordion-content uk-padding uk-margin-large-left uk-margin-large-right uk-border-rounded uk-text">
        {renderLinks(item.answer)}
      </div>
    </li>
  )
}

export default function SupportFaq({ faqs }) {
  return (
    <>
      <span id="faq" />
      <SectionLight>
        <div className="uk-grid" uk-grid="true">
          <div className="uk-width-1-1">
            <h2 className="uk-heading-divider">Frequently asked questions</h2>
          </div>

          {/* general faqs */}
          <div className="uk-width-1-1">
            <h3>General FAQs</h3>
            <ul uk-accordion="true">
              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  What are the SCAN Dashboards?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <h4>About the SCAN Public Dashboard</h4>
                  <p>
                    The SCAN Public Dashboard tracks the number of participants
                    with SCAN-compliant MRI and PET images and the total number
                    of images submitted by each Alzheimer’s Disease Research
                    Center (ADRC) to SCAN. This resource is meant to provide
                    ADRCs and researchers with up-to-date information on the
                    number of standard images that are available for analysis.{" "}
                    <a
                      href="https://youtu.be/ZO34bDc15hY?si=hvembMTf4T4h_RRL"
                      target="_blank"
                      rel="noreferrer"
                      className="uk-button uk-button-text"
                    >
                      View a demo of the SCAN Public Dashboard
                    </a>
                    .
                  </p>
                  <div
                    className="uk-grid-divider uk-child-width-1-2@s"
                    data-uk-grid
                  >
                    <div>
                      <h5>Information included in the Dashboard:</h5>
                      <ul className="uk-list uk-list-bullet">
                        <li>
                          <p>
                            Links to each center's ADRC-specific SCAN QC
                            Dashboard
                          </p>
                        </li>
                        <li>
                          <p>
                            Total number of MRI and PET participants for whom
                            the site has submitted data to SCAN
                          </p>
                        </li>
                        <li>
                          <p>
                            Total number of standard MRI exams and series
                            submitted to SCAN that are available for analysis
                          </p>
                        </li>
                        <li>
                          <p>
                            Total number of PET scans (amyloid or tau) submitted
                            to SCAN that are available for analysis
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h5>
                        Information <u>NOT</u> included in the Dashboard:
                      </h5>
                      <ul className="uk-list uk-list-bullet">
                        <li>
                          <p>SCAN analysis data for ADRCs</p>
                        </li>
                        <li>
                          <p>
                            SCAN analysis data for Researchers: ADRD researchers
                            around the world may access this data via NACC's
                            Quick Access File beginning in late 2023.
                          </p>
                        </li>
                        <li>
                          <p>
                            ADRC-specific PET and MRI data: The MRI and PET
                            summary and QC status information is available
                            through the ADRC-specific SCAN QC dashboards
                          </p>
                        </li>
                        <li>
                          <p>
                            Data for participants without existing NACCIDs: For
                            the time being LONI will only be accepting data
                            submissions for participants who have existing
                            NACCIDs
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr />
                  <h4>About the ADRC-specific SCAN QC Dashboards</h4>
                  <p>
                    Each ADRC can access their ADRC-specific SCAN QC dashboards
                    via the SCAN Public Dashboard located above. The
                    ADRC-specific SCAN QC dashboards will:
                  </p>
                  <ul className="uk-list uk-list-collapse uk-list-decimal">
                    <li>
                      <p>
                        Enable them to track the QC status of their MRI and PET
                        submissions, allowing them to rapidly address errors.
                      </p>
                    </li>
                    <li>
                      <p>
                        Serve as an auditable record of all SCAN MRI data
                        submitted to SCAN by a given ADRC, downloadable as a csv
                        file.
                      </p>
                    </li>
                  </ul>

                  <p className="uk-text-bolder uk-text-center">
                    <span className="uk-label">IMPORTANT NOTE:</span> The
                    ADRC-specific SCAN QC Dashboards are only available to ADRC
                    members.
                  </p>
                  <h5>
                    Information that will be included in the ADRC-specific SCAN
                    dashboards includes:
                  </h5>
                  <div
                    className="uk-child-width-1-2@s uk-grid-divider"
                    data-uk-grid
                  >
                    <li>
                      <p>
                        All <b>PET</b> summary and QC data submitted by each
                        ADRC including:
                      </p>
                      <ul className="uk-list uk-list-collapse uk-list-bullet">
                        <li>
                          <p>NACCIDs</p>
                        </li>
                        <li>
                          <p>PTIDs</p>
                        </li>
                        <li>
                          <p>PET study date and time</p>
                        </li>
                        <li>
                          <p>Image UID & Scanner model</p>
                        </li>
                        <li>
                          <p>PET radiotracer used</p>
                        </li>
                        <li>
                          <p>Whether the scan passed QC or not</p>
                        </li>
                        <li>
                          <p>QC comments (why the scan failed QC, if failed)</p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <p>
                        All <b>MRI</b> summary and QC data submitted by each
                        ADRC including:
                      </p>
                      <ul className="uk-list uk-list-collapse uk-list-bullet">
                        <li>
                          <p>NACCIDs</p>
                        </li>
                        <li>
                          <p>PTIDs</p>
                        </li>
                        <li>
                          <p>MRI study date and time</p>
                        </li>
                        <li>
                          <p>Image UID & Scanner model</p>
                        </li>
                        <li>
                          <p>Series date and time</p>
                        </li>
                        <li>
                          <p>Series number</p>
                        </li>
                        <li>
                          <p>Series type</p>
                        </li>
                        <li>
                          <p>Study comments: Visual artifacts, etc.</p>
                        </li>
                        <li>
                          <p>
                            Release for Analysis: Whether the series was
                            released for analysis or not based on QC and
                            protocol checks
                          </p>
                        </li>
                      </ul>
                    </li>
                  </div>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  What is the current process for submitting MRI/PET images?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <p>
                    <span className="bolder">As of March 17, 2023</span>, sites
                    will only be able to submit SCAN MRI and PET images to LONI
                    for participants that already have a NACCID. This change
                    will prevent errors (i.e., duplicate NACC IDs for
                    participants, etc.) from being introduced into the database
                    and the need for future SCAN data audits. See{" "}
                    <a
                      href="#loni"
                      title="Changes to the LONI Uploader"
                      className="bolder"
                      data-uk-scroll="offset: 20"
                    >
                      Changes to the LONI Uploader
                    </a>{" "}
                    section for more information.
                  </p>
                  <div className="">
                    <span className="bolder">
                      Please note that this is a temporary change.{" "}
                    </span>
                    Sites are encouraged to continue collecting SCAN data on all
                    of their participants but will not be able to submit data
                    for participants without existing NACC IDs until a new
                    process for assigning NACCIDs to SCAN participants is put in
                    place. NACC hopes to provide further updates to ADRCs on
                    this new process soon.
                  </div>
                  <h4 className="bolder uk-text uk-text-justify uk-width-2xlarge ">
                    What about non-SCAN compliant MRI/PET images that are
                    submitted to NACC?
                  </h4>
                  <div className="">
                    <p>
                      As of March 17, 2023, sites will only be able to submit
                      non-standard (non-SCAN compliant) MRI and PET images to
                      NACC for participants that already have pre-existing
                      NACCIDs.
                    </p>
                    <div className="uk-alert uk-alert-warning" data-uk-alert>
                      Please do not submit non-standard MRI and PET images to
                      SCAN as
                      <b>
                        {" "}
                        the SCAN initiative only collects SCAN-compliant MRI and
                        PET images.
                      </b>
                    </div>
                    <p>
                      Existing MRI and PET images collected before January 1,
                      2021, and prospective images that don&apos;t meet SCAN
                      protocol are still accepted by NACC.
                    </p>
                    <a
                      href="/data-collection/nonstandard-image-submission"
                      className="bolder"
                    >
                      Learn more about submitting this data to NACC here.
                    </a>
                  </div>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  How are MR/PET image submissions being tracked?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <h4>
                    SCAN-compliant MRI/PET images submitted to LONI as part of
                    the SCAN initiative:
                  </h4>
                  <ul className="uk-list uk-list-bullet">
                    <li>
                      <p>
                        <a data-uk-scroll={"offset: 20"} href="#dashboard">
                          SCAN Public Dashboard:
                        </a>
                        The Public SCAN Dashboard tracks the number of
                        SCAN-compliant MRI/PET images and the number of
                        participants that images have been submitted for by each
                        ADRC to SCAN.
                      </p>
                    </li>
                    <li>
                      <p>
                        <a
                          data-uk-scroll={"offset: 20"}
                          href="#about-dashboard"
                        >
                          ADRC-Specific SCAN QC Dashboards{" "}
                        </a>
                        As part of the ADRC Portals, each ADRC will have access
                        to their ADRC-specific SCAN QC dashboards that will a)
                        enable them to track the summary and QC status of their
                        MRI and PET submissions, allowing them to rapidly
                        address errors, and b) serve as an auditable record of
                        all SCAN MRI data submitted to LONI/NACC by a given
                        ADRC, downloadable as a csv file.
                      </p>
                    </li>
                    <li>
                      <p>
                        The NACC monthly reports for each ADRC{" "}
                        <span className="uk-text-bold">do not </span>
                        include the MRI/PET images submitted to LONI for the
                        SCAN project in the image counts. We will not be adding
                        SCAN images to these static monthly reports because we
                        are in the process of modernizing the NACC Data Platform
                        and complete SCAN-compliant image counts will be
                        available through the SCAN dashboards.
                      </p>
                    </li>
                  </ul>
                  <h4 className="uk-text uk-text-bold">
                    Non-SCAN compliant MRI/PET images submitted to NACC:
                  </h4>
                  <ul className="uk-list uk-list-bullet">
                    <li>
                      <p>
                        NACC is currently in discussion with NIA and the Imaging
                        Steering Committee to determine how legacy MRI and PET
                        images will be tracked and reported at NACC as we
                        transition to our modern{" "}
                        <a href="#future" data-uk-scroll="offset: 20" title="">
                          Data Platform and ADRC portals
                        </a>
                        .
                      </p>
                    </li>
                    <li>
                      <p>
                        Your monthly report should currently reflect the correct
                        number of legacy MRI and PET images that have been
                        submitted to by your site to NACC.
                      </p>
                    </li>
                    <li>
                      <p>
                        There were previously some errors in these counts due to
                        a bug. However, we believe all errors with counts of
                        images at NACC have now been corrected. If you
                        experience an error, please reach out to{" "}
                        <a href="mailto:nacchelp@uw.edu">nacchelp@uw.edu</a>.
                      </p>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  How will MR/PET image submissions be tracked in the future?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <div className="uk-text-bold">
                    As NACC completes its transition onto our new modern data
                    platform, we will launch the following resources:
                  </div>
                  <h4>ADRC Portals</h4>
                  <p>
                    The ADRC Portals will provide ADRCs with self-service access
                    to view, download, and audit their real-time data. These
                    portals will replace inefficient static monthly reports with
                    modern real-time ADRC-specific data portals (ADRC Portals),
                    fulfilling an emergent request from ADRCs to have better
                    access to their data submissions.
                  </p>
                  <p>
                    The ADRC Portals will provide ADRCs with access to six core
                    data/metadata types:
                  </p>
                  <ul className="uk-list uk-list-bullet uk-list-collapse">
                    <li>
                      <p>UDS</p>
                    </li>
                    <li>
                      <p>Neuropathology</p>
                    </li>
                    <li>
                      <p>Non-standard MRI/PET</p>
                    </li>
                    <li>
                      <p>Standard MRI/PET</p>
                    </li>
                    <li>
                      <p>Biospecimen (NCRAD) and Genetic/genomic (NIAGADS)</p>
                    </li>
                    <li>
                      <p>Participant Diversity Metrics</p>
                    </li>
                  </ul>
                  <p className="">
                    NACC is currently working to build the ADRC Portals within
                    NACC&apos;s new Data Platform (powered by Flywheel).
                    Flywheel will support the need to make this data accessible
                    only to designated ADRC representatives, requiring
                    management by ADRC personnel and the technology platform to
                    allow access control for appropriate viewers. NACC is using
                    user centered design to develop dashboards to adapt the
                    Flywheel interface to the ADRC programs&apos; unique needs.
                  </p>
                  <div className="uk-width-2-3@m uk-margin-auto">
                    <StaticImage
                      src="../assets/images/portal-overview.png"
                      alt="Data Portal overview"
                      class="uk-border-rounded"
                    />
                    <div className="uk-text-meta uk-margin-small-top">
                      Overview of the ADRC Data Portals that will allow for each
                      center to have self-service access to their patient UDS,
                      neuropathology, non-standard MRI/PET, diversity metrics,
                      ADRC standard MRI/PET, and specimen/marker/genetic
                      metadata.
                    </div>
                  </div>
                  <h4>NACC&apos;s new Data Platform</h4>
                  <p>
                    The NACC Data Platform (NACC DP) that will integrate a host
                    of existing and new standardized and longitudinal data
                    streams from across the ADRC Program as well as metadata and
                    analysis from partner organizations and initiatives, with
                    all data streams linked by NACC Identifiers (NACC IDs). The
                    NACC DP will include a variety of data search and access
                    interfaces including the Data Front Door, ADRC Data Portals,
                    and the NIA Portal.
                  </p>
                  <p>
                    These systems have been designed with an eye towards needing
                    a modern tools that will not only accommodate our existing
                    long-standing data streams such as the Uniform Data Set
                    (UDS), neuropathology reports, and non-standard MRI/PET but
                    that will also scale to accommodate and share innovative new
                    data streams such as standardized imaging data, digital
                    neuropathology, and digital biomarkers, and that can
                    integrate complementary metadata and analysis data for
                    standardized MRI/PET imaging (SCAN/LONI), genetic/genomic
                    (NIAGADS) and biomarker (NCRAD) data from other ADRD data
                    repositories, with all data streams linked by NACC unique
                    identifiers (NACC IDs).
                  </p>
                  <p>
                    The transition to this new data platform will begin by
                    giving access to ADRC portals in which each center will be
                    able to access their data and view dashboards allowing them
                    to track their contributions to the ADRC program. NACC will
                    increasingly add functionality to these portals, ultimately
                    replacing old processes like emailed monthly reports.
                  </p>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  How are identifiers used for SCAN data?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <p>
                    When submitting MRI/PET images to SCAN via the LONI
                    uploader, you are currently asked to enter the Subject ID or
                    PTID. This number is different from the NACCID as described
                    below:{" "}
                  </p>
                  <ul className="uk-list uk-list-bullet">
                    <li>
                      <p>
                        <b>Subject ID or PTID</b>: this is the ADRC-managed
                        participant ID, or &quot;local ID&quot;. Formats vary by
                        ADRC.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>NACCID</b>: this is the NACC-managed participant ID.
                        It is a string with the prefix &apos;NACC&apos; followed
                        by 6 digits.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>ADCID</b>: this is the NACC-managed center ID. This
                        is a two-digit number.
                      </p>
                    </li>
                  </ul>
                  <p>Each PTID and ADCID pair maps to a unique NACCID.</p>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  How will ADRCs contributing data to SCAN be acknowledged?
                </AnchorLink>
                <div className="uk-accordion-content uk-padding uk-border-rounded uk-text">
                  <p>
                    NACC will build off the current NACC Data Use Agreement
                    (DUA) policy to provide researchers with a comprehensive
                    list of ADRCs and other related grants who have contributed
                    their data to SCAN. All researchers and person(s) requesting
                    data from NACC (including SCAN data in the future) must
                    accept the NACC DUA policy.{" "}
                  </p>
                  <p>
                    The current NACC DUA policy requires authors to include the
                    following statement acknowledging the NACC grant and Center
                    support in presentations and the acknowledgments section of
                    manuscripts:{" "}
                  </p>
                  <div className="uk-padding-small ">
                    <q className="uk-text-primary">
                      The NACC database is funded by NIA/NIH Grant U24 AG072122.
                      NACC data are contributed by the NIA-funded ADRCs: P30
                      AG...., etc.
                    </q>
                  </div>
                  <p>
                    If your site is involved in the SCAN initiative and you have
                    additional grants or updates to include in the
                    acknowledgements, please reach out to{" "}
                    <a href="mailto:naccmail@uw.edu" className="bolder">
                      naccmail@uw.edu
                    </a>
                    .
                  </p>
                </div>
              </li>
            </ul>
          </div>

          {/* adrc-specific faqs */}
          {faqs.data.map((category) => {
            return (
              <div className="uk-width-1-1" key={category.section}>
                <h3>{category.section}</h3>
                <ul uk-accordion="true">
                  {category.faqs.map((faq) => faqItem(faq))}
                </ul>
              </div>
            )
          })}
        </div>
      </SectionLight>
    </>
  )
}
