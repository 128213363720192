import React from "react"
import SectionLight from "../components/sections/section-light"
import Collaborators from "./collaborators"

export default function Team() {
  return (
    <>
      <span id="team" />
      <SectionLight>
        <h2>Meet the Team</h2>
        <hr />
        <div className="uk-grid uk-grid-divider" data-uk-grid="true">
          <div className="uk-width-1-2@m">
            <h4>
              Standardized Centralized Alzheimer’s and Related Dementias
              Neuroimaging (SCAN) Initiative Team
            </h4>
            <div
              className="uk-grid uk-child-width-1-2@s"
              data-uk-grid="masonry: pack"
            >
              <div>
                <h4>MRI Team</h4>
                <ul className="uk-list uk-list-collapse">
                  <li>
                    <p>
                      <b>
                        Clifford Jack, MD - Mayo Clinic (Principal Investigator)
                      </b>
                    </p>
                  </li>
                  <li>
                    <p>Charles DeCarli, MD</p>
                  </li>
                  <li>
                    <p>Chadwick Ward</p>
                  </li>
                  <li>
                    <p>Pauline Maillard</p>
                  </li>
                  <li>
                    <p>Christopher Schwarz</p>
                  </li>
                  <li>
                    <p>Denise Reyes</p>
                  </li>
                  <li>
                    <p>Bret Borowski</p>
                  </li>
                  <li>
                    <p>John Moore-Weiss</p>
                  </li>
                  <li>
                    <p>Leonard Matoush</p>
                  </li>
                  <li>
                    <p>Robert Reid</p>
                  </li>
                  <li>
                    <p>Anne Effron</p>
                  </li>
                  <li>
                    <p>Gregory Preboske </p>
                  </li>
                  <li>
                    <p>Jeffrey Gunter </p>
                  </li>
                  <li>
                    <p>Matthew Senjem </p>
                  </li>
                  <li>
                    <p>Colin Hortman</p>
                  </li>
                  <li>
                    <p>Kejal Kantarci</p>
                  </li>
                  <li>
                    <p>Oliver Martinez</p>
                  </li>
                </ul>
              </div>
              <div>
                <h4>PET Team</h4>
                <ul className="uk-list uk-list-collapse">
                  <li>
                    <p>
                      <b>
                        William Jagust, MD - UC Berkeley (Principal
                        Investigator)
                      </b>
                    </p>
                  </li>
                  <li>
                    <p>Bob Koeppe</p>
                  </li>
                  <li>
                    <p>Susan Landau</p>
                  </li>
                  <li>
                    <p>Suzanne Baker</p>
                  </li>
                  <li>
                    <p>Tessa Harrison</p>
                  </li>
                  <li>
                    <p>Trevor Chadwick</p>
                  </li>
                </ul>
              </div>
              <div>
                <h4>LONI Team</h4>
                <ul className="uk-list uk-list-collapse">
                  <li>
                    <p> Arthur Toga</p>
                  </li>
                  <li>
                    <p>Karen Crawford</p>
                  </li>
                </ul>
              </div>
              <div>
                <h4 className="">NACC Team</h4>
                <ul className="uk-list uk-list-collapse">
                  <li>
                    <p> Sarah Biber </p>
                  </li>
                  <li>
                    <p> Bud Kukull </p>
                  </li>
                  <li>
                    <p> Brittany Hale </p>
                  </li>
                  <li>
                    <p> Brendan Smith </p>
                  </li>
                  <li>
                    <p> Zach Stark </p>
                  </li>
                  <li>
                    <p> Jessica Culhane </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@m">
            <h4>Collaborators</h4>
            <Collaborators />
          </div>
        </div>
      </SectionLight>
    </>
  )
}
