import React from "react"
import { StaticImage } from "gatsby-plugin-image"
export default function Collaborators() {
  return (
    <div className="uk-grid uk-flex-center" data-uk-grid="masonry: pack">
      <a
        href="https://www.berkeley.edu/"
        title="Berkeley University of California"
      >
        <StaticImage
          src="../assets/images/berkeley.png"
          height={100}
          quality={100}
          alt="Berkeley University of California"
        />
      </a>
      <a
        href="https://www.lbl.gov/"
        title="Lawrence Berkeley National Laboratory"
      >
        <StaticImage
          src="../assets/images/berkeley-lab.png"
          height={100}
          quality={100}
          alt="Lawrence Berkeley National Laboratory"
        />
      </a>
      <a href="https://umich.edu/" title="University of Michigan">
        <StaticImage
          src="../assets/images/umich.png"
          height={100}
          alt="University of Michigan"
          quality={100}
        />
      </a>
      <a
        href="https://www.mayo.edu/research/labs/aging-dementia-imaging/overview"
        title="Mayo Clinic"
      >
        <StaticImage
          src="../assets/images/mayo.png"
          height={100}
          quality={100}
          alt="Mayo Clinic"
        />
      </a>
      <a href="https://www.ucdavis.edu/" title="UC Davis">
        <StaticImage
          src="../assets/images/davis.png"
          height={100}
          quality={100}
          alt="UC Davis"
        />
      </a>
      <a
        href="https://naccdata.org/"
        title="National Alzheimer's Coordination Center"
      >
        <StaticImage
          src="../assets/images/nacc-uw-stack.png"
          height={100}
          quality={100}
          alt="National Alzheimer's Coordinating Center"
        />
      </a>
      <a href="https://loni.usc.edu/" title="Laboratory of Neuro Imaging">
        <StaticImage
          src="../assets/images/loni.png"
          height={100}
          quality={100}
          alt="Laboratory of Neuro Imaging"
        />
      </a>
      <a href="https://www.nih.gov/" title="National Institutes of Health">
        <StaticImage
          src="../assets/images/nih.png"
          height={100}
          quality={100}
          alt="National Institutes of Health"
        />
      </a>
    </div>
  )
}
