import React from "react"
import Dashboard from "./scan-dashboard"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faFileAlt } from "@fortawesome/free-solid-svg-icons"
import SectionDefault from "./sections/section-default"

export default function DashboardSection() {
  return (
    <>
      <span id="dashboard" />
      <SectionDefault>
        <h2 className="uk-heading-divider">SCAN Dashboard</h2>
        <div
          className="uk-margin-top uk-grid-small uk-child-width-1-2@m"
          data-uk-grid="true"
        >
          <p>
            This dashboard tracks the number of Standardized Centralized
            Alzheimer’s and Related Dementias Neuroimaging (SCAN)-compliant
            MR/PET images and the number of participants that images have been
            submitted for by each Alzheimer’s Disease Research Center (ADRC) to
            the Laboratory of Neuro Imaging (LONI) for the SCAN Initiative.
            <br />
            <em>
              Due to delays caused by the defacing process, these counts may
              differ slightly from the total number of submitted images.{" "}
            </em>
          </p>
          <div className="uk-margin-auto uk-margin-bottom">
            <div className="uk-grid uk-child-width-1-1@s uk-padding-small">
              <p className="bolder uk-text-center uk-text-large">
                New to the SCAN Dashboards?
              </p>
              <div className="uk-text-center">
                <p className="bolder">Check out the </p>
                <a
                  href="https://files.alz.washington.edu/scan/scan_dashboard_quick_guide.pdf"
                  title="SCAN Quick Guide"
                  className="uk-button uk-button-primary bolder"
                >
                  SCAN Dashboard Quick Guide
                  <FontAwesomeIcon
                    icon={faFileAlt}
                    size={"1x"}
                    className="uk-margin-small-left"
                  />
                </a>
                <p className="bolder uk-margin-small">or</p>
                <a
                  href="#faq"
                  data-uk-scroll="offset: 20"
                  title="Learn more"
                  className="uk-button uk-button-primary  bolder"
                >
                  Learn more about the SCAN Dashboards
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    size={"1x"}
                    className="uk-margin-small-left"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Dashboard />
        <p className="uk-text-meta">
          Please note: Only ADRC members with data access privileges can view
          the ADRC-Specific SCAN QC Dashboards. Learn how to get access in the{" "}
          <a
            href="https://files.alz.washington.edu/scan/scan_dashboard_quick_guide.pdf"
            className="uk-button-text"
          >
            SCAN Dashboard Quick Guide
          </a>
          .
        </p>
      </SectionDefault>
    </>
  )
}
