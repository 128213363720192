import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SectionDark from "./sections/section-dark"
import { Link } from "gatsby"

export default function NewUpdates() {
  return (
    <>
      <span id="updates" />
      <SectionDark>
        <div className="uk-grid" uk-grid="true">
          <div className="uk-width-1-1">
            <h2 className="uk-heading-divider">SCAN Updates</h2>
          </div>

          <div className="uk-width-1-1">
            <ul uk-accordion="true">
              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  <h4>SCAN MR and PET Image Availability</h4>
                </AnchorLink>
                <div className="uk-accordion-content uk-margin-remove uk-padding-remove ">
                  <div className="uk-section-primary text-color-light uk-section uk-padding">
                    <p>
                      As of August 2024, researchers can now request defaced
                      SCAN images via NACC's Quick Access File Data Request
                      System, in addition to the SCAN numerical data collected
                      from all participating ADRCs which includes summary, QC,
                      and analysis (volumes, cortical thickness, surface area,
                      and SUVRs) data.
                    </p>
                    <p>
                      SCAN MRI and PET Data is submitted by several Alzheimer’s
                      Disease Researcher Centers (ADRCs) following clearly
                      defined SCAN MRI and PET acquisition protocols. All SCAN
                      image data has been collected and submitted after January
                      2021.
                    </p>
                    <p>
                      Documentation, such as the SCAN MRI and PET Data
                      Dictionaries can be found in the{" "}
                      <Link
                        className="uk-button uk-button-text"
                        to="#data-resources"
                      >
                        Data Resources
                      </Link>{" "}
                      section above.
                    </p>
                    <p className="uk-text-italic">
                      NOTE: Due to the processing time needed to perform
                      defacing, not all numerical SCAN data will have associated
                      MR or PET image data available to researchers at this
                      time.
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  <h4>PET Summary and Analysis Data Availability</h4>
                </AnchorLink>
                <div className="uk-accordion-content uk-margin-remove uk-padding-remove ">
                  <div className="uk-section-primary text-color-light uk-section uk-padding">
                    <p>
                      In January 2024, SCAN PET summary and analysis (SUVRs)
                      data was returned to ADRCs that have contributed to SCAN.
                      Sites were sent a summary report of the PET data each site
                      has uploaded to SCAN, in addition to the PET numerical
                      data analysis report which contains the derived outputs of
                      the PET image data after it has passed through the SCAN
                      PET QC pipeline, which includes QC of the incoming images
                      as well as QC inspection of the products of the analytic
                      pipelines.
                    </p>
                    <h5>The PET data file contained the following:</h5>
                    <ul className="uk-list uk-list-collapse uk-list-bullet uk-margin-left">
                      <li>
                        <p>PET summary and QC data </p>
                      </li>
                      <li>
                        <p>PET SUVRs </p>
                      </li>
                    </ul>
                    <p>
                      {" "}
                      Documentation, such as the SCAN PET Researcher’s Data
                      Dictionary, Methods, and other documentation can be found{" "}
                      <a
                        href="https://naccdata.org/data-collection/forms-documentation/biomarker-imaging#researchers%20data%20dictionary%20%20scan%20pet"
                        title="PET Researcher's Dictionary"
                        className="uk-text-bold uk-button uk-button-text"
                      >
                        here
                      </a>
                      .{" "}
                    </p>
                    <p>
                      NOTE: Due to the processing time needed to perform
                      defacing, QC, and analysis on submitted data, not all data
                      that has been submitted and/or passed through QC has
                      analysis data associated with it.{" "}
                    </p>
                    {/* <div
                      data-uk-grid="true"
                      className="uk-grid uk-child-width-1-2@s "
                    >
                      <div>
                        <a
                          href="https://files.alz.washington.edu/scan/scan_data_dictionary_pet.xlsx"
                          className="uk-button uk-button-secondary"
                        >
                          SCAN PET Data Dictionary
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://files.alz.washington.edu/scan/ucberkeley_mrifree_npdka_appendix.csv"
                          className="uk-button uk-button-secondary"
                        >
                          NPDKA (MRI-free) Appendix
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://files.alz.washington.edu/scan/UCBerkeley_SCAN_Amyloid_MRIfree_Methods_20230818.pdf"
                          className="uk-button uk-button-secondary"
                        >
                          SCAN Amyloid (MRI-free) Methods
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://files.alz.washington.edu/scan/UCBerkeley_SCAN_Tau_MRIfree_Methods_20230818.pdf"
                          className="uk-button uk-button-secondary"
                        >
                          SCAN Tau (MRI-free) Methods
                        </a>
                      </div>
                    </div> 

                    <hr />
                    <h4>SCAN MRI Summary and Analysis Data</h4>
                    <h5>
                      In March 2024, SCAN MRI summary and analysis (analysis)
                      data was returned to ADRCs that have contributed MRI data
                      to SCAN.
                    </h5>
                    <p>
                      Sites were sent a summary report of the MRI data each site
                      has uploaded to SCAN, in addition to the MRI volumetric
                      analysis report which contains the derived outputs of the
                      MRI image data after it has passed through the SCAN MRI QC
                      pipeline.
                    </p>
                    <h5>The MRI data file contained the following:</h5>
                    <ul className="uk-list uk-list-collapse uk-list-bullet uk-margin-left">
                      <li>MRI summary and QC data</li>
                      <li>
                        MRI brain volumes, cortical thickness, and surface area
                        data
                      </li>
                    </ul>

                    <h5>About the SCAN analysis process</h5>
                    <p>
                      The SCAN MR analysis team performs standard processing of
                      all MR images that have passed QC using FreeSurfer to
                      provide standard readouts. These include the parcellations
                      of regional brain volumes, cortical thickness and surface
                      area for 31 bilateral gray matter regions from the
                      Desikan-Killiany atlas.
                    </p>

                    <p>
                      <span className="uk-text-bold">NOTE:</span> Due to the
                      processing time needed to perform defacing, QC, and
                      analysis on submitted data, not all data that has been
                      submitted and/or passed through QC has analysis data
                      associated with it.
                    </p>

                    <h5>
                      Please refer to the following resources for more details
                      and descriptions of the data.
                    </h5>
                    <div
                      data-uk-grid="true"
                      className="uk-grid uk-margin-left uk-child-width-1-2@s "
                    >
                      <div>
                        <a
                          href="https://s3.us-west-2.amazonaws.com/files.alz.washington.edu/scan/scan_data_dictionary_MRI.xlsx"
                          className="uk-button uk-button-secondary"
                        >
                          SCAN MRI Data Dictionary
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://files.alz.washington.edu/scan/SCAN_MRI_Analysis_Methods.pdf"
                          className="uk-button uk-button-secondary"
                        >
                          SCAN MRI Methods
                        </a>
                      </div>
                    </div>

                    <hr />
                    <h4>About the SCAN Summary and Analysis Data</h4>

                    <div
                      className="uk-child-width-expand@m uk-grid-divider"
                      data-uk-grid="true"
                    >
                      <div>
                        <h5>SCAN Summary and QC Data</h5>
                        <p>
                          The summary data includes information about all the
                          MRI and PET image data that participating ADRCs have
                          contributed to SCAN.
                        </p>
                        <p>Includes:</p>
                        <ul className="uk-list uk-list-collapse uk-list-bullet uk-margin-left">
                          <li>
                            NACCIDs (links all other data at NACC for a given
                            participant)
                          </li>
                          <li>Scanner model (for both PET and MRI)</li>
                          <li>PET scan date and time</li>
                          <li>PET radiotracer</li>
                          <li>MRI study time and date</li>
                          <li>MRI series date and time</li>
                          <li>MRI series number</li>
                          <li>MRI series type</li>
                          <li>
                            QC status (for all PET and MRI images available)
                          </li>
                        </ul>
                      </div>
                      <div className="uk-panel uk-panel-muted">
                        <h5>SCAN Analysis Data</h5>
                        <p>
                          The analysis data contains the derived outputs of the
                          MRI and PET image data that has been uploaded by
                          participating ADRCs. Please note that all outputs
                          provided have passed through the MRI/PET QC pipeline,
                          which includes QC of the incoming images as well as QC
                          inspection of the products of the analytic pipelines.
                        </p>
                        <p>Includes:</p>
                        <ul className="uk-list uk-list-collapse uk-list-bullet uk-margin-left">
                          <li>MRI brain volumes</li>
                          <li>MRI cortical thickness</li>
                          <li>MRI surface area</li>
                          <li>PET SUVRs</li>
                        </ul>
                      </div>
                    </div>
                    <div className="uk-margin-top">
                      <p>
                        For information about SCAN data availability for
                        researchers, navigate to{" "}
                        <a
                          href="#access"
                          className="uk-button uk-button-text uk-text-bold"
                          data-uk-scroll="offset: 20"
                        >
                          How and when can researchers access SCAN data?
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  <h4>MRI Summary and Analysis Data Availability</h4>
                </AnchorLink>
                <div className="uk-accordion-content uk-margin-remove uk-padding-remove ">
                  <div className="uk-section-primary text-color-light uk-section uk-padding ">
                    <p>
                      In March 2024, SCAN MRI summary and analysis (analysis)
                      data was returned to ADRCs that have contributed MRI data
                      to SCAN. Sites were sent a summary report of the MRI data
                      each site has uploaded to SCAN, in addition to the MRI
                      volumetric analysis report which contains the derived
                      outputs of the MRI image data after it has passed through
                      the SCAN MRI QC pipeline.
                    </p>
                    <h5>The MRI data file contained the following:</h5>
                    <ul className="uk-list uk-list-collapse uk-list-bullet uk-margin-left">
                      <li>
                        <p>MRI summary and QC data</p>
                      </li>
                      <li>
                        <p>
                          MRI brain volumes, cortical thickness, and surface
                          area data
                        </p>
                      </li>
                    </ul>
                    <h5>About the SCAN analysis process</h5>
                    <p>
                      The SCAN MR analysis team performs standard processing of
                      all MR images that have passed QC using FreeSurfer to
                      provide standard readouts. These include the parcellations
                      of regional brain volumes, cortical thickness and surface
                      area for 31 bilateral gray matter regions from the
                      Desikan-Killiany atlas.{" "}
                    </p>
                    <p>
                      NOTE: Due to the processing time needed to perform
                      defacing, QC, and analysis on submitted data, not all data
                      that has been submitted and/or passed through QC has
                      analysis data associated with it.{" "}
                    </p>
                    <p>
                      Documentation, such as the SCAN MRI Researcher’s Data
                      Dictionary, Methods, and other documentation can be found{" "}
                      <a
                        href="https://naccdata.org/data-collection/forms-documentation/biomarker-imaging#researchers%20data%20dictionary%20%20scan%20mri"
                        title="MRI Data Dictionary"
                        className="uk-text-bold uk-button uk-button-text"
                      >
                        here
                      </a>
                      .{" "}
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <AnchorLink className="uk-accordion-title" to="">
                  <h4>The LONI uploader includes a new verification step</h4>
                </AnchorLink>
                <div className="uk-accordion-content uk-margin-remove uk-padding-remove ">
                  <div className="uk-section-primary text-color-light uk-section uk-padding ">
                    <div className="uk-text uk-margin-small-bottom">
                      Beginning July 29, 2023 the LONI imaging data submission
                      interface will require that sites enter both the PTID and
                      the NACCID for a given participant to submit their MRI/PET
                      data to SCAN. This change was introduced to ensure NACCID
                      and data fidelity for the SCAN initiative. As shared in
                      our ADRC-wide communication on March 17, 2023, ADRCs are
                      currently only able to submit SCAN data for participants
                      with existing NACCIDs. More details about this temporary
                      limitation and next steps can be found in the What is the
                      current process for submitting MRI/PET images? section of
                      this page.
                    </div>
                    <div className="uk-margin-small-bottom uk-margin-top">
                      <h4>
                        How to correctly submit your data via the new LONI
                        uploader
                      </h4>
                      <ul className="uk-list uk-list-bullet uk-margin-left">
                        <li>
                          <p>
                            Sites must enter both identifiers into the Subject
                            ID text field using the convention PTID+NACCID,
                            where PTID and NACCID are separated by the{" "}
                            <code>+</code> character.
                          </p>
                          <ul>
                            <li>
                              <p>
                                If the given PTID and NACCID pair match the
                                record present in the NACC database, the upload
                                process will be allowed to continue.
                              </p>
                            </li>
                            <li>
                              <p>
                                If not, then the user will receive an error
                                message indicating that the PTID and NACCID
                                combination are not valid, and the upload will
                                not be allowed to continue.
                              </p>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <p>
                            Users who do not follow the PTID+NACCID convention
                            will see a message describing the expected
                            formatting of the Subject ID.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <h4>How do I find the NACCIDs for my participants?</h4>
                    <p>
                      To access your site’s PTID and NACCID pairs, please work
                      with your site’s data manager to utilize the{" "}
                      <a
                        className="uk-text-bold uk-button uk-button-text"
                        href="https://files.alz.washington.edu/documentation/PTID-to-NACCID-map-instructions.pdf"
                        title="Detailed upload instructions"
                      >
                        PTID to NACCID Map
                      </a>{" "}
                      tool available via the NACC portal.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </SectionDark>
    </>
  )
}
