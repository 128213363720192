import React from "react"

export function renderLinks(string) {
  // Matches markdown style hyperlinks: [link](www.url.com)
  const regex = /\[(.*?)\]\((.*?)\)/gm

  // If string does not contain a url, just render it.
  if (!regex.test(string)) return <span>{string}</span>

  // Otherwise, split the string on that link
  const stringArr = string.split(regex)

  // Generate array of <span> and <a> tags from split strings
  let position = 0
  let result = []
  while (position < stringArr.length) {
    if (!(position % 3)) {
      // Text get <span> tags
      result.push(<span>{stringArr[position]}</span>)
      position += 1
    } else {
      // <a> tags get href= and text
      result.push(
        <a
          href={stringArr[position + 1]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {stringArr[position]}
        </a>
      )
      position += 2
    }
  }

  return result
}
