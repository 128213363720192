import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import SectionHome from "../components/section-home"
import About from "../components/about"
import Resources from "../components/resources"
import SupportFaq from "../components/support-faq"
import Team from "../components/team"

import FaqData from "../content/faqs.json"
import Footer from "../components/footer"
import NewUpdates from "../components/new-updates"
import DashboardSection from "../components/dashboard-section"
import Contact from "../components/contact"

// const hasUpdates = updates && updates.data.length > 0
const hasUpdates = false // For now, silence updates

export default function IndexPage() {
  return (
    <Layout hasUpdates={hasUpdates}>
      <Seo title="Home" />

      {/* hero */}
      <SectionHome />

      {/* about */}
      <About />

      {/* dashboard */}
      <DashboardSection />

      {/* protocols */}
      <Resources />

      {/* team & contact & copyright */}
      <Team />

      {/* updates */}
      {/* <UpdateLister hasUpdates={hasUpdates} updates={updates} /> */}
      <NewUpdates />

      {/* support & FAQs */}
      <SupportFaq faqs={FaqData} />

      {/* contact */}
      <Contact />

      {/* footer */}
      <Footer />
    </Layout>
  )
}
