import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <footer className="uk-section">
      <div
        className="uk-grid uk-grid-divider uk-grid-match"
        data-uk-grid="true"
      >
        <div className="uk-width-1-5@m">
          <a
            href="https://naccdata.org/"
            title="NACC logo: link to home page"
            className="uk-flex uk-flex-middle uk-flex-center nacc-logo"
          >
            <StaticImage
              src={"../assets/images/nacc-uw-stack.png"}
              alt="National Alzheimer's Coordinating Center"
              height={120}
              width={120}
              quality={100}
            />
          </a>
        </div>
        <div className="uk-width-3-4@m uk-text-small">
          <div className="uk-grid-small" data-uk-grid="true">
            <div className="uk-width-1-1">
              <p>
                If you’re unable to find what you’re looking for, please don’t
                hesitate to contact us.
              </p>
            </div>
            <div className="uk-width-1-1 uk-flex">
              <p>
                <strong>
                  4311 &ndash; 11th Avenue NE, Suite 300, Seattle, WA 98105
                </strong>
                <span className="uk-margin-small-right uk-margin-small-left">
                  |
                </span>
                <strong>NACCmail@uw.edu</strong>
                <span className="uk-margin-small-right uk-margin-small-left">
                  |
                </span>
                <strong>
                  <a
                    className="uk-text-primary"
                    href="https://naccdata.org/nacc-collaborations/stay-connected"
                  >
                    NACC Updates
                  </a>
                </strong>
                <span className="uk-margin-small-right uk-margin-small-left">
                  |
                </span>
                <strong>
                  <a
                    className="uk-text-primary"
                    href="https://naccdata.org/careers"
                  >
                    Careers
                  </a>
                </strong>
                <span className="uk-margin-small-right uk-margin-small-left">
                  |
                </span>
                <strong>
                  <a
                    className="uk-text-primary"
                    href="https://naccdata.org/sitemap"
                  >
                    Sitemap
                  </a>
                </strong>
              </p>
            </div>
            <div className="uk-width-1-1">
              <ul className="uk-iconnav">
                <li>
                  <a
                    href="https://www.youtube.com/c/NACCNationalAlzheimersCoordinatingCenter/featured"
                    data-uk-icon="icon: youtube"
                    className="uk-icon-link"
                    aria-label="NACC YouTube channel"
                  ></a>
                </li>
                <li>|</li>
                <li>
                  <a
                    href="https://twitter.com/NACCData"
                    data-uk-icon="icon: twitter"
                    aria-label="NACC Twitter"
                  ></a>
                </li>
                <li>|</li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/naccdata/"
                    data-uk-icon="icon: linkedin"
                    aria-label="NACC LinkedIn"
                  ></a>
                </li>
              </ul>
            </div>
            <div className="uk-width-expand">
              <p>
                NACC is funded by the National Institute on Aging (U24 AG072122)
                and located in the Department of Epidemiology at the University
                of Washington School of Public Health, Walter A. Kukull, Ph.D.,
                Director. &copy; {new Date().getFullYear()} University of
                Washington
              </p>
              <div className="uk-width-1-1"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
