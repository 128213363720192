import { useState, useEffect, useMemo } from "react"
import scan_readiness from "../content/scan_readiness_status.json"

export default function useScanData() {
  const [data, setData] = useState([])
  const [lastModified, setLastModified] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://loni-table-data.s3.us-west-2.amazonaws.com/v_scan_upload_with_qc_summary.json"
        )
        console.error(response)
        if (!response.ok) {
          throw new Error(response)
        }
        const lastModified = response.headers.get("last-modified")
        setLastModified(lastModified)
        const fetchedData = await response.json()

        const filteredData = fetchedData.filter(
          (row) => row.center_name !== "Grand Total"
        )

        const editedData = filteredData.map((row) => {
          const centerStatus = scan_readiness.find(
            (adrc) => adrc.center_name === row.center_name
          )
          row["scan_readiness"] = centerStatus
            ? centerStatus.readiness
            : "not found"
          row["url"] = centerStatus ? centerStatus.dashboard_url : null
          row["display_name"] = centerStatus.display_name
          return row
        })

        setData(editedData)
        setLoading(false)
      } catch (error) {
        console.error(error)
        setError(error.message)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  // Memoize the data so it doesn't cause unnecessary re-renders
  const memoizedData = useMemo(() => data, [data])

  return { data: memoizedData, lastModified, loading, error }
}
