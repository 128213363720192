import React from "react"

export default function Submit() {
  return (
    <div className="uk-grid-divider" uk-grid="true">
      <div className="uk-width-1-2@m">
        <h4>Submit SCAN-Compliant data to LONI</h4>
        <p>
          Please carefully review the{" "}
          <a
            href="https://files.alz.washington.edu/scan/SCAN_PET_Manual.pdf"
            title="SCAN PET Manual"
          >
            SCAN PET manual
          </a>{" "}
          and the{" "}
          <a
            href="https://files.alz.washington.edu/scan/SCAN_MRI_Manual.pdf"
            title="SCAN MRI Manual"
          >
            SCAN MRI manual
          </a>{" "}
          to ensure that your data is compliant with the SCAN protocol and for
          uploading instructions.
        </p>
        <p>
          Only SCAN-compliant data should be submitted to the Laboratory of
          Neuro Imaging (LONI). Non-approved data (with or without approved
          data) will be quarantined. Images collected before 2021 do not meet
          SCAN-compliance criteria.
        </p>
        <div>
          <a
            href="https://ida.loni.usc.edu/"
            title="Upload SCAN-compliant data to LONI"
            className="uk-button uk-button-primary"
          >
            Submit SCAN-compliant MRI/PET data
          </a>
        </div>
      </div>
      <div className="uk-width-1-2@m">
        <h4>Submit non–SCAN-compliant data directly to NACC</h4>
        <p>
          SCAN does not accept data that is not prospectively collected and
          SCAN-compliant.
        </p>
        <p>
          Any data that that is 'heterogenous' or non-SCAN-compliant should be
          submitted to NACC.
        </p>
        <div>
          <a
            href="https://naccdata.org/data-collection/nonstandard-image-submission"
            title="upload heterogenous 'non-SCAN-compliant' data to NACC"
            className="uk-button uk-button-primary"
          >
            Submit Heterogenous MRI/PET data
          </a>
        </div>
      </div>
    </div>
  )
}
